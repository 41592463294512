@use 'vars' as *;

@font-face {
    font-family: meritocracy;
    src: url('../assets/font.woff');
}

body {
    font-family: proxima-nova, sans-serif;
    font-size: 16px;
    color: black;
}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%;
}
img {
    height:auto !important;
}
table{
    border: 0 !important;;
    td{
        border-bottom: 1px solid #000;
    }
    th{
        text-transform: uppercase;
        font-weight: 700;
        font-family: proxima-nova, sans-serif;
    }
}
.form-control table td {
	border-bottom: none;
}
.clear-float {
    clear:both;
}

.highlight {
    color:#bbbbbb;
    font-size:40px;
}

.button {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    padding: 10px 20px;
    max-width: 100%;
    color: #FFF;
    background-color: grey;
    border: 1px solid grey;
    box-sizing: border-box;
    transition: all 0.3s ease;
}
.button:hover {
    color: grey;
    background-color: transparent;
}

img.display-left {
    float:left;
    max-width: 50%;
    margin-right:20px;
    margin-bottom:10px;
}

img.display-right {
    float:right;
    max-width: 50%;
    margin-left:20px;
    margin-bottom:10px;
}
.cursive{
    font-family: meritocracy, cursive;
    font-weight: 100;
}
.black-size{
    font-weight: 900;
}
.btn-outline{
    background-color:transparent;
    border:1px solid white;
    padding:10px;
}
.red{
    color:$red;
}
.bold{
    font-weight: bold;
}
.light{
    font-weight: 300;
}
.red-btn, a.red-btn{
    border: 1px solid $red;
    color:$red!important;
    &:hover{
        color:darken($red, 10%)!important;
    }
    text-decoration: none;
    font-weight: 900;
    padding:.5rem 1rem;
    background-color: transparent;
}
@media only screen and (max-width: 760px){
    img.display-left,
    img.display-right {
        float: none;
        max-width: 100%;
        margin: 20px 0;
    }
}